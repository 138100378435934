
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');


#wrapper header .container {
    position: relative;
}

#wrapper  header .container .login {
    position: absolute;
    top: 20px;
    right: 10px;
}

.btn-facebook {
    background: #3c5999;
    color: #fff!important;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 15px;
    border-radius: 20px;
    min-width: 260px;
}
.voteButton{
    background: black !important;
    border-radius: 3rem !important;
    height: 3rem !important;
    color: white !important;
    font-size: 1.3rem !important;
    font-weight: 600 !important;
}
.nombre-politico{
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    font-size: 1.2rem;
}
.numero-politico{
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
}
.competitor-img{
    display: flex;
    justify-content: center;
}
.competitor-img-33{
    display: flex;
    justify-content: center;
    height: 20rem;
}
.nombre-politico-detalle{
    height: 3rem;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
}

.opacarImg {
    filter: brightness(0.3);
  }
.img-fluid{
    border-radius: 50%;
}


.btn-google {
    background: #dd4b39;
    color: #fff!important;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 15px;
    border-radius: 20px;
    min-width: 260px;
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
    box-shadow: none;
}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus, .btn:active, .btn:hover{
    box-shadow: none!important;
    outline: 0;
}

#user-box {
    background-color: #6c757d;
    color: #fff;
}
#user-box .dropdown-item {color: #fff;}
#user-box .dropdown-item.active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}
#user-box .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: #707b83;
}

.partido {
    margin-bottom: 1rem;
    border-radius: 10px;
}




.competitor {
    position: relative;
    background: white;
    border-radius: 1rem;
    border: solid 0.5rem white;
}
.competitor .competitor-btn { margin: 10px 0; height: 8rem; }
.btn-woman { color:#fff; background-color: #e39fa6; width: 100% }
.btn-man { color:#fff; background-color: #91b1c2;  width: 100% }






.mt-6 {margin-top: 6rem!important;}
.mt-7 {margin-top: 9rem!important;}
.mt-8 {margin-top: 12rem!important;}

#lang-box {
    background-color: #6c757d;
    color: #fff;
}
#lang-box .dropdown-item {color: #fff;}
#lang-box .dropdown-item.active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}
#lang-box .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: #707b83;
}

#home-page { position:absolute; top:20px; left:10px}
